<template>
  <b-button
    v-if="option.value === 'none'"
    class="buttons-select-option-link"
    size="md"
    variant="link"
    @click="optionClicked()"
  >
    <span> {{ option.name }} </span>
  </b-button>
  <b-button
    v-else
    :aria-label="`${option.name}, ${option.type}, ${price} per month, ${option.payment}.`"
    class="buttons-select-option-button"
    size="lg"
    variant="outline-primary"
    @click="optionClicked()"
  >
    <div
      v-if="option.type"
      class="buttons-select-option-content-left float-left"
    >
      <span class="buttons-select-option-content-left-main float-left">{{ option.name }}</span>
      <span class="buttons-select-option-content-left-sub float-left">{{ option.type }}</span>
    </div>
    <div
      v-else
      class="buttons-select-option-content-center"
    >
      <span class="buttons-select-option-content-left-main float-left">{{ option.name }}</span>
    </div>
    <div
      v-if="option.payment"
      class="buttons-select-option-content-right float-right"
    >
      <span class="buttons-select-option-content-right-main">{{ option.displayPrice }}</span>
      <span class="buttons-select-option-content-right-sub">{{ option.payment }}</span>
    </div>
    <div
      v-else
      class="buttons-select-option-content-center float-right"
    >
      <span class="buttons-select-option-content-right-main">{{ option.displayPrice }}</span>
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'FormButtonsSelectOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    nextStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    price() {
      if (!this.option.price) return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.option.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      }).format(this.option.price / 100);
    },
  },
  methods: {
    // TODO set and get needs to be passed in
    optionClicked() {
      this.$store.commit('SET_HARDWARE_SELECTED', this.option.value);
      this.$store.commit('SET_STEP', this.nextStep);
    },
  },
};
</script>

<template>
  <div id="guest-upgrade-step-7">
    <!-- SHIPPING ADDRESS SECTION -->
    <FormSection
      v-if="showShippingInfo"
      title="Shipping Address"
      class="w-100"
    >
      <SignupSummaryInfo
        :step="4"
        variant="shipping"
        link-text="Change"
      />
    </FormSection>
    <!-- BILLING ADDRESS SECTION -->
    <FormSection
      title="Billing Address"
      class="w-100"
    >
      <SignupSummaryInfo
        :step="6"
        variant="billing"
        link-text="Change"
      />
    </FormSection>
    <!-- PAYMENT METHOD INFO -->
    <FormSection
      v-if="cc"
      title="Payment Method"
      class="w-100"
    >
      <SignupSummaryInfo
        :step="6"
        variant="payment"
        link-text="Change"
      />
    </FormSection>
    <!-- ORDER SUMMARY -->
    <SignupOrderSummary />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormSection from '../Form/FormSection.vue';
import SignupOrderSummary from '../Signup/SignupComponents/SignupOrderSummary.vue';
import SignupSummaryInfo from '../Signup/SignupComponents/SignupSummaryInfo.vue';

export default {
  name: 'GuestUpgradeStep7',
  components: {
    FormSection,
    SignupOrderSummary,
    SignupSummaryInfo,
  },
  computed: {
    ...mapGetters([
      'hardwareSelected',
      'cc',
    ]),
    showShippingInfo() {
      if (this.hardwareSelected !== 'none') {
        return true;
      }
      return false;
    },
  },
};
</script>

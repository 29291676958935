<template>
  <div id="guest-upgrade">
    <!-- recurly script -->
    <div>
      <script2 src="https://js.recurly.com/v4/recurly.js" />
      <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
      <!-- eslint-disable -->
      <script2>
        $(document).ready(function () {
          recurly.configure({
            publicKey: '{{publicKey}}',
          });
        });
        recurly.on('field:submit', function (event) {
          event.preventDefault();
        });

        $('form').on('submit', function (event) {
          event.preventDefault();
          var form = this;

          recurly.token(form, function (err, token) {
            if (err) {
              console.log(err);
              alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
            } else {
              $('#token').val(token.id);
              $('#upgradeButton').click();
            }
          });
        });
      </script2>
      <!-- eslint-enable -->
    </div>
    <!-- HEADING - TITLE -->
    <div
      ref="title"
      class="guest-upgrade-title"
      tabindex="0"
      v-html="titleHtml"
    />
    <!-- HEADING - IMAGE -->
    <img
      v-if="step === 2"
      :aria-hidden="true"
      src="../../assets/horizon.svg"
      alt="Horizon"
    >
    <!-- HEADING - SUBTITLE -->
    <p>{{ subtitle }}</p>
    <!-- ERROR HANDLING -->
    <FormErrors />
    <form
      class="guest-upgrade-container"
      @submit.prevent
    >
      <!-- STEPS -->
      <!-- <router-view/> -->
      <GuestUpgradeStep1 v-if="showStep(1)" />
      <GuestUpgradeStep2 v-if="showStep(2)" />
      <GuestUpgradeStep3 v-if="showStep(3)" />
      <GuestUpgradeStep4 v-if="showStep(4)" />
      <GuestUpgradeStep5 v-show="showStep(5)" />
      <GuestUpgradeStep6 v-show="showStep(6)" />
      <GuestUpgradeStep7 v-if="showStep(7)" />
      <input
        id="token"
        ref="token"
        type="hidden"
        name="recurly-token"
        data-recurly="token"
      >
      <div class="signup-buttons d-flex justify-content-center flex-wrap">
        <b-button
          v-if="showContinueButton && !showStep(6)"
          ref="progressButton"
          :disabled="buttonDisabled"
          :block="true"
          variant="primary"
          size="md"
          @click="clickContinueHandler"
        >
          {{ continueButtonText }}
        </b-button>
        <b-button
          v-if="showContinueButton && showStep(6)"
          ref="progressButton"
          :disabled="buttonDisabled"
          :block="true"
          type="submit"
          variant="primary"
          size="md"
        >
          {{ continueButtonText }}
        </b-button>
        <b-btn
          id="upgradeButton"
          hidden
          @click="handleStep6Continue()"
        />
        <b-button
          v-if="showBackButton"
          :disabled="buttonDisabled"
          :block="true"
          variant="link"
          size="md"
          @click="clickBackHandler"
        >
          Back
        </b-button>
      </div>
      <!-- AGREEMENT CLAUSE -->
      <SignupAgreementClause
        v-if="step == 7"
        :links="true"
        variant="guestUpgrade"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateEmail } from '@/utils/validation';
import { UPDATE_PAYMENT_TYPE } from '@/store/mutation-types';
import ValidationMixin from '../../mixin/ValidationMixin';

import GuestUpgradeStep1 from './GuestUpgradeStep1.vue';
import GuestUpgradeStep2 from './GuestUpgradeStep2.vue';
import GuestUpgradeStep3 from './GuestUpgradeStep3.vue';
import GuestUpgradeStep4 from './GuestUpgradeStep4.vue';
import GuestUpgradeStep5 from './GuestUpgradeStep5.vue';
import GuestUpgradeStep6 from './GuestUpgradeStep6.vue';
import GuestUpgradeStep7 from './GuestUpgradeStep7.vue';

import FormErrors from '../Form/FormErrors.vue';
import SignupAgreementClause from '../Signup/SignupComponents/SignupAgreementClause.vue';

export default {
  name: 'GuestUpgrade',
  components: {
    GuestUpgradeStep1,
    GuestUpgradeStep2,
    GuestUpgradeStep3,
    GuestUpgradeStep4,
    GuestUpgradeStep5,
    GuestUpgradeStep6,
    GuestUpgradeStep7,
    FormErrors,
    SignupAgreementClause,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      dev: process.env.NODE_ENV === 'development',
      prod: process.env.NODE_ENV === 'production',
      staging: process.env.VUE_APP_ENV_NAME === 'dev',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
      isMobile: false,
      buttonDisabled: false,
      is7DayTrialEnded: false,
    };
  },
  computed: {
    ...mapGetters([
      'userId',
      'step',
      'planCode',
      'plan',
      'errors',
      'hardwareSelected',
      'horizonSize',
      'horizonTint',
      'firstName',
      'lastName',
      'noPhone',
      'phoneNumber',
      'email',
      'shippingAddress1',
      'shippingCountry',
      'shippingCity',
      'shippingState',
      'shippingZip',
      'address1',
      'country',
      'city',
      'state',
      'zip',
      'password',
      'paymentType',
      'countryCodeOptions',
      'noEmail',
      'noPhone',
      'countryOptions',
      'stateOptions',
      'country',
      'hardwareSelected',
      'sameBillingShipping',
    ]),
    showSameBillingShipping() {
      return (this.hardwareSelected !== 'none');
    },
    pageTitle() {
      switch (this.step) {
        case 1: return '';
        case 2: return 'Add Horizon Kit';
        case 3: return 'Kit Preferences';
        case 4: return 'Shipping';
        case 5: return 'Profile';
        case 6: return 'Billing';
        case 7: return 'Checkout';
        default: return 'Loading . . .';
      }
    },
    stepProgress() {
      if (this.hardwareSelected !== 'none') {
        switch (this.step) {
          case 1:
          case 2: return '';
          case 3: return ' - Step 1 of 5';
          case 4: return ' - Step 2 of 5';
          case 5: return ' - Step 3 of 5';
          case 6: return ' - Step 4 of 5';
          case 7: return ' - Step 5 of 5';
          default: return '';
        }
      } else {
        switch (this.step) {
          case 1:
          case 2:
          case 3:
          case 4: return '';
          case 5: return ' - Step 1 of 3';
          case 6: return ' - Step 2 of 3';
          case 7: return ' - Step 3 of 3';
          default: return '';
        }
      }
    },
    titleHtml() {
      return `<h1
        class="guest-upgrade-title">
        ${this.title}
      </h1>`;
    },
    title() {
      switch (this.step) {
        case 1:
          if (this.is7DayTrialEnded) return 'Thank you for trying Aira!';
          return 'Choose a plan that works for you';
        case 2: return 'Would you like to go hands-free with Aira\'s Horizon Kit?';
        case 3: return 'Customize your Horizon Smart Glasses';
        case 4: return 'Where should we deliver your Horizon Kit?';
        case 5: return 'Tell us about yourself';
        case 6: return 'Enter your payment details';
        case 7: return 'Review your purchase summary';
        default: return 'Loading . . .';
      }
    },
    subtitle() {
      switch (this.step) {
        case 1:
          if (this.is7DayTrialEnded) return 'If you\'d like to continue connecting with Agents anytime, anywhere, and for anything, upgrade to an Explorer plan today.';
          return 'Connect with Agents anytime, anywhere.';
        case 2: return 'The Horizon Kit comes with a voice-controlled smartphone tethered to a pair of smart glasses.';
        case 3: return 'Prescription lenses are not supported.';
        case 4:
        case 5:
        case 6:
        case 7: return '';
        default: return 'Loading . . .';
      }
    },
    continueButtonText() {
      switch (this.step) {
        case 1:
        case 2: return '?';
        case 3: return 'Continue to Shipping';
        case 4: return 'Continue to Profile';
        case 5: return 'Continue to Billing';
        case 6: return 'Continue to Checkout';
        case 7: return 'Complete Purchase';
        default: return 'Loading . . .';
      }
    },
    showContinueButton() {
      switch (this.step) {
        case 1:
        case 2: return false;
        case 3:
        case 4:
        case 5:
        case 6:
        case 7: return true;
        default: return false;
      }
    },
    showBackButton() {
      switch (this.step) {
        case 1:
        case 2: return false;
        case 3:
        case 4:
        case 5:
        case 6: return true;
        case 7:
        default: return false;
      }
    },
  },
  watch: {
    step(val) {
      this.$store.commit('CLEAR_ERRORS');
      this.buttonDisabled = false;

      // return focus to top of page on page change
      window.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$refs.title.focus();
      });

      // prevent out of bound step, reset if out of bound
      if (val < 1 || val > 7) {
        // eslint-disable-next-line
        alert('HOW DARE YOU. Returning to the start.');
        this.$store.commit('SET_STEP', 1);
      }

      // google analytics screen tracking on step change
      if (this.prod) this.trackPage();

      // set page title
      document.title = `${this.pageTitle}${this.stepProgress}`;

      // this.$router.push(`/guest-upgrade/step${val}`);
    },
    planCode() {
      this.$store.dispatch('setPlan');
    },
  },
  async created() {
    // google analytics
    if (this.prod) {
      this.trackPage();
    }

    // promotions
    if (this.$route.query) {
      if (this.$route.query['7DayTrialEnded']) {
        if (this.$route.query['7DayTrialEnded'] !== '0') {
          this.is7DayTrialEnded = true;
          if (this.userId) this.$store.dispatch('updatePropertyValue', { propertyKey: 'sevenDayTrialExpireMessageRead', data: [{ value: true }] });
        }
      }
    }

    if (window.webkit || window.appInterface) this.isMobile = true;

    // set page title
    document.title = '';

    // hard-code to CC (future work will use this in case we support ACH/manual payments
    this.$store.commit(UPDATE_PAYMENT_TYPE, 'CC');

    // this.$router.push('/guest-upgrade/step1');
  },
  methods: {
    // GOOGLE ANALYTICS
    trackPage() {
      this.$ga.page({
        page: this.$route.fullPath,
        title: `Aira Guest Upgrade - Step ${this.step}, ${this.title}`,
      });
    },
    // UTILITIES
    showStep(step) {
      return this.step === step;
    },
    // FORM HANDLERS
    clickContinueHandler() {
      this.buttonDisabled = true;
      switch (this.step) {
        case 1:
        case 2:
          this.$store.commit('INCREMENT_STEP');
          break;
        case 3:
          this.handleStep3Continue();
          break;
        case 4:
          this.handleStep4Continue();
          break;
        case 5:
          this.handleStep5Continue();
          break;
        case 6:
          this.handleStep6Continue();
          break;
        case 7:
          this.handleStep7Continue();
          break;
        default:
      }
    },
    clickBackHandler() {
      this.buttonDisabled = true;
      switch (this.step) {
        case 1:
        case 2:
        case 3:
        case 4:
          this.$store.commit('DECREMENT_STEP');
          break;
        case 5:
          this.$store.commit('SET_STEP', 1);
          break;
        case 6:
        case 7:
          this.$store.commit('DECREMENT_STEP');
          break;
        default:
      }
    },
    handleStep3Continue() {
      this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      if (!this.horizonSize) fieldErrors.push('Horizon Size');
      if (!this.horizonTint) fieldErrors.push('Horizon Tint');

      this.$store.commit('PUSH_ERRORS', fieldErrors);
      this.buttonDisabled = false;
      if (!this.errors.length) {
        this.$store.commit('INCREMENT_STEP');
      }
    },
    handleStep4Continue() {
      this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      if (this.shippingCountry === 'other') fieldErrors.push('Sorry, we currently only operate in the US, Canada, UK, Australia, and New Zealand. Please check aira.io/global for more information.');
      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.shippingAddress1) fieldErrors.push('Address');
      if (!this.shippingCountry) fieldErrors.push('Country');
      if (!this.shippingCity) fieldErrors.push('City');
      if (!(this.shippingCountry === 'GB') && !this.shippingState) fieldErrors.push('State/Region/Province');
      if (!this.shippingZip) fieldErrors.push('Zip/Postal Code');

      this.$store.commit('PUSH_ERRORS', fieldErrors);
      this.buttonDisabled = false;
      if (!this.errors.length) {
        this.$store.commit('INCREMENT_STEP');
      }
    },
    handleStep5Continue() {
      this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.lastName) fieldErrors.push('Last Name');
      if (this.noPhone && this.phoneNumber.length < 7) fieldErrors.push('Phone Number');
      if (!this.email) fieldErrors.push('Email');
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (this.password.length < 8) fieldErrors.push('Password');

      this.$store.commit('PUSH_ERRORS', fieldErrors);
      this.buttonDisabled = false;
      if (!this.errors.length) {
        this.$store.commit('INCREMENT_STEP');
      }
    },
    async handleStep6Continue() {
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }

      this.$store.commit('CLEAR_ERRORS');
      let fieldErrors = [];

      if (this.country === 'other') fieldErrors.push('Sorry, we currently only operate in the US, Canada, UK, Australia, and New Zealand. Please check aira.io/global for more information.');

      // validation
      const validation = await this.$validator.validateAll();
      if (!validation) {
        const recurlyErrors = await this.veeErrors.items.map((item) => item.msg);
        fieldErrors = fieldErrors.concat(recurlyErrors);
      }

      if (fieldErrors.length) {
        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.buttonDisabled = false;
      } else {
        try {
          const response = await this.$store.dispatch('getOrderPreview');
          if (response) {
            this.$store.commit('INCREMENT_STEP');
          } else {
            this.buttonDisabled = false;
          }
        } catch (error) {
          console.error(error);
          this.buttonDisabled = false;
        }
      }
    },
    handleStep7Continue() {
      this.$store.commit('CLEAR_ERRORS');
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
        this.submit();
      }
    },
    async submit() {
      this.$store.dispatch('doSignup').then((response) => {
        if (response) {
          this.$cookies.remove('ssoToken');
          this.$cookies.remove('phoneNumber');
          this.$cookies.remove('email');
          if (this.$cookies.get('userId')) this.$cookies.remove('userId');
          try {
            // try to send the message to iOS
            if (window.webkit) {
              window.webkit.messageHandlers.appInterface.postMessage('GUEST_UPGRADE_SUCCESS');
            } else if (window.appInterface) { // android custom binding
              window.appInterface.postMessage('GUEST_UPGRADE_SUCCESS');
            } else { // default web
              window.location.href = `https://aira.io/get-started-confirmation?userId=${response}`;
            }
          } catch (err) {
            console.error(err);
            this.buttonDisabled = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/theme/_mobile.scss';

#guest-upgrade {
  color: $body-color;
  padding: 0 15px;
  .guest-upgrade-title {
    padding-top: 20px;
    outline: 0 !important;
    font-size: x-large;
  }
  .guest-upgrade-container {
    height: 100%;
    text-align: left;
    padding-top: 25px;
  }
  button {
    border-radius: 3px;
    font-weight: 600;
  }
  .signup-buttons {
    margin-top: 25px;
  }
}
</style>

<template>
  <div id="guest-upgrade-step-5">
    <!-- Account INFORMATION -->
    <!-- FIRST & LAST NAME -->
    <div class="d-flex justify-content-between">
      <FormGroup
        for="firstName"
        label="First Name*"
        class="w-50"
      >
        <FormInput
          v-validate="{ required: true }"
          :state="validateState('First Name')"
          name="First Name"
          bind-id="firstName"
          data-recurly="first_name"
          invalid-feedback="First Name Required."
          invalid-feedback-id="first-name-invalid-feedback"
          get="firstName"
          set="UPDATE_FIRST_NAME"
          required
        />
      </FormGroup>
      <FormGroup
        for="lastName"
        label="Last Name*"
        class="w-50"
      >
        <FormInput
          v-validate="{ required: true }"
          :state="validateState('Last Name')"
          name="Last Name"
          bind-id="lastName"
          data-recurly="last_name"
          invalid-feedback="Last Name Required."
          invalid-feedback-id="last-name-invalid-feedback"
          get="lastName"
          set="UPDATE_LAST_NAME"
          required
        />
      </FormGroup>
    </div>
    <!-- PHONE NUMBER -->
    <FormGroup
      v-if="!noPhone"
      for="phone"
      label="Phone Number"
      class="w-100"
    >
      <FormInput
        bind-id="phone"
        get="phone"
        set="SET_PHONE"
        readonly
      />
    </FormGroup>
    <!-- COUNTRY CODE & PHONE NUMBER -->
    <div
      v-if="noPhone"
      class="d-flex justify-content-between"
    >
      <FormGroup
        for="countryCode"
        label="Country"
        class="w-25-35"
      >
        <FormSelect
          :options="countryCodeOptions"
          bind-id="countryCode"
          default="+1"
          get="countryCode"
          set="UPDATE_COUNTRY_CODE"
        />
      </FormGroup>
      <FormGroup
        for="phoneNumber"
        label="Phone Number"
        class="w-75-65"
      >
        <FormInput
          bind-id="phoneNumber"
          get="phoneNumber"
          set="UPDATE_PHONE_NUMBER"
        />
      </FormGroup>
    </div>
    <!-- EMAIL -->
    <FormGroup
      for="email"
      label="Email Address"
      class="w-100"
    >
      <FormInput
        :readonly="!noEmail"
        bind-id="email"
        type="email"
        get="email"
        set="UPDATE_EMAIL"
      />
    </FormGroup>
    <!-- PASSWORD -->
    <FormGroup
      for="password"
      label="Password (At least 8 characters)"
      class="w-100 tip"
    >
      <FormInput
        bind-id="password"
        type="password"
        get="password"
        set="UPDATE_PASSWORD"
      />
    </FormGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ValidationMixin from '../../mixin/ValidationMixin';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'GuestUpgradeStep5',
  components: {
    FormGroup,
    FormInput,
    FormSelect,
  },
  mixins: [
    ValidationMixin,
  ],
  computed: {
    ...mapGetters([
      'countryCodeOptions',
      'noEmail',
      'noPhone',
    ]),
  },
};
</script>

<template>
  <div id="guest-upgrade-step-4">
    <!-- SHIPPING ADDRESS SECTION -->
    <!-- FIRST & LAST NAME -->
    <div class="d-flex justify-content-between">
      <FormGroup
        for="firstName"
        label="First Name"
        class="w-50"
      >
        <FormInput
          bind-id="firstName"
          get="firstName"
          set="UPDATE_FIRST_NAME"
          required
        />
      </FormGroup>
      <FormGroup
        for="lastName"
        label="Last Name"
        class="w-50"
      >
        <FormInput
          bind-id="lastName"
          get="lastName"
          set="UPDATE_LAST_NAME"
          required
        />
      </FormGroup>
    </div>
    <!-- ADDRESS 1 -->
    <FormGroup
      for="shippingAddress1"
      label="Address 1"
      class="w-100"
    >
      <FormInput
        bind-id="shippingAddress1"
        get="shippingAddress1"
        set="UPDATE_SHIPPING_ADDRESS1"
        required
      />
    </FormGroup>
    <!-- ADDRESS 2 -->
    <FormGroup
      for="shippingAddress2"
      label="Address 2 (Optional)"
      class="w-100"
    >
      <FormInput
        bind-id="shippingAddress2"
        get="shippingAddress2"
        set="UPDATE_SHIPPING_ADDRESS2"
      />
    </FormGroup>
    <!-- COUNTRY & CITY & STATE & ZIP -->
    <div class="d-flex flex-wrap justify-content-between">
      <!-- COUNTRY -->
      <FormGroup
        for="shippingCountry"
        label="Country"
        class="w-50"
      >
        <FormSelect
          :options="countryOptions"
          bind-id="shippingCountry"
          get="shippingCountry"
          set="UPDATE_SHIPPING_COUNTRY"
          required
        />
      </FormGroup>
      <!-- CITY -->
      <FormGroup
        for="shippingCity"
        label="City"
        class="w-50"
      >
        <FormInput
          bind-id="shippingCity"
          get="shippingCity"
          set="UPDATE_SHIPPING_CITY"
        />
      </FormGroup>
      <!-- STATE/REGION/PROVINCE -->
      <FormGroup
        v-if="shippingStateOptions.length"
        for="shippingState"
        label="State/Region/Province"
        class="w-50"
      >
        <FormSelect
          :options="shippingStateOptions"
          bind-id="shippingState"
          get="shippingState"
          set="UPDATE_SHIPPING_STATE"
        />
      </FormGroup>
      <!-- ZIP -->
      <FormGroup
        for="shippingZip"
        label="Zip/Postal Code"
        class="w-50"
      >
        <FormInput
          bind-id="shippingZip"
          get="shippingZip"
          set="UPDATE_SHIPPING_ZIP"
        />
      </FormGroup>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'GuestUpgradeStep4',
  components: {
    FormGroup,
    FormInput,
    FormSelect,
  },
  computed: {
    ...mapGetters([
      'countryOptions',
      'shippingStateOptions',
      'shippingCountry',
    ]),
  },
};
</script>

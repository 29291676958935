<template>
  <div
    :id="bindId"
    v-bind="$attrs"
  >
    <FormButtonsSelectOption
      v-for="(option, index) in options"
      :key="index"
      :option="option"
      :next-step="option.value !== 'none' ? 3 : 5"
    />
    <!-- TODO next step to not have magic numbers -->
  </div>
</template>

<script>
import FormButtonsSelectOption from './FormButtonsSelectOption.vue';

export default {
  name: 'FormButtonsSelect',
  components: {
    FormButtonsSelectOption,
  },
  props: {
    bindId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

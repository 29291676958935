<template>
  <div id="guest-upgrade-step-2">
    <FormButtonsSelect
      v-if="hardwareOptions.length"
      :options="hardwareOptions"
      bind-id="hardwareOptions"
      aria-label="hardware options"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormButtonsSelect from '../Form/FormButtonsSelect.vue';

export default {
  name: 'GuestUpgradeStep2',
  components: {
    FormButtonsSelect,
  },
  computed: {
    ...mapGetters([
      'hardwareOptions',
    ]),
  },
};
</script>

<template>
  <div id="guest-upgrade-step-6">
    <!-- PAYMENT INFO -->
    <FormSection
      title="Card Information"
      class="w-100"
    >
      <!-- CARD INFO -->
      <FormGroup
        for="cc"
        label="Card Number*"
        class="w-100"
      >
        <div
          class="recurly-field"
          role="none"
          data-recurly="number"
        />
      </FormGroup>
      <!-- EXPIRATION & CVC -->
      <div class="d-flex justify-content-between flex-wrap">
        <FormGroup
          for="expiryMonth"
          label="Expiration Month (MM)*"
          class="w-30-100"
        >
          <div
            class="recurly-field"
            role="none"
            data-recurly="month"
          />
        </FormGroup>
        <FormGroup
          for="expiryYear"
          label="Expiration Year (YYYY)*"
          class="w-30-100"
        >
          <div
            class="recurly-field"
            role="none"
            data-recurly="year"
          />
        </FormGroup>
        <FormGroup
          for="cvv"
          label="CVV*"
          class="w-30-100"
        >
          <FormInput
            :state="null"
            name="CVV"
            bind-id="cvv"
            data-recurly="cvv"
            invalid-feedback="CVV Required."
            invalid-feedback-id="cvv-invalid-feedback"
            get="cvv"
            set="UPDATE_CVV"
            required
          />
        </FormGroup>
      </div>
    </FormSection>

    <!-- BILLING INFORMATION -->
    <FormSection
      title="Billing Address"
      class="w-100"
    >
      <FormGroup
        v-if="showSameBillingShipping"
        for="sameBillingShipping"
        class="w-100"
      >
        <FormInput
          bind-id="sameBillingShipping"
          type="checkbox"
          label="Same as shipping address"
          get="sameBillingShipping"
          set="TOGGLE_SAME_BILLING_SHIPPING"
          class="w-100"
        />
      </FormGroup>
      <div v-if="!(sameBillingShipping && showSameBillingShipping)">
        <!-- ADDRESS 1 -->
        <FormGroup
          for="address1"
          label="Address*"
          class="w-100"
        >
          <FormInput
            v-validate="{ required: true }"
            :state="validateState('Address')"
            name="Address"
            bind-id="address1"
            data-recurly="address1"
            invalid-feedback="Address Required."
            invalid-feedback-id="address1-invalid-feedback"
            get="address1"
            set="UPDATE_ADDRESS1"
            required
          />
        </FormGroup>
        <!-- COUNTRY & CITY & STATE & ZIP -->
        <div class="d-flex flex-wrap justify-content-between">
          <!-- COUNTRY -->
          <FormGroup
            for="country"
            label="Country*"
            class="w-50-100"
          >
            <FormSelect
              :options="countryOptions"
              :state="null"
              name="country"
              bind-id="country"
              data-recurly="country"
              invalid-feedback="Country Required."
              invalid-feedback-id="country-invalid-feedback"
              get="country"
              set="UPDATE_COUNTRY"
              required
            />
          </FormGroup>
          <!-- CITY -->
          <FormGroup
            for="city"
            label="City*"
            class="w-50-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('City')"
              name="City"
              bind-id="city"
              data-recurly="city"
              invalid-feedback="City Required."
              invalid-feedback-id="city-invalid-feedback"
              get="city"
              set="UPDATE_CITY"
              required
            />
          </FormGroup>
          <!-- STATE/REGION/PROVINCE -->
          <FormGroup
            for="state"
            label="State/Province/Region*"
            class="w-50-100"
          >
            <FormSelect
              :options="stateOptions"
              :state="null"
              name="state"
              bind-id="state"
              data-recurly="state"
              invalid-feedback="State Required."
              invalid-feedback-id="state-invalid-feedback"
              get="state"
              set="UPDATE_STATE"
              required
            />
          </FormGroup>
          <!-- ZIP -->
          <FormGroup
            for="zip"
            label="Zip or Postal Code*"
            class="w-50-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Zip')"
              name="Zip"
              bind-id="zip"
              data-recurly="postal_code"
              invalid-feedback="Zip or Postal Code Required."
              invalid-feedback-id="zip-invalid-feedback"
              get="zip"
              set="UPDATE_ZIP"
              required
            />
          </FormGroup>
        </div>
      </div>
    </FormSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ValidationMixin from '../../mixin/ValidationMixin';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'GuestUpgradeStep6',
  components: {
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
  },
  mixins: [
    ValidationMixin,
  ],
  computed: {
    ...mapGetters([
      'countryOptions',
      'stateOptions',
      'country',
      'hardwareSelected',
      'sameBillingShipping',
    ]),
    showSameBillingShipping() {
      return (this.hardwareSelected !== 'none');
    },
  },
};
</script>

<template>
  <div id="guest-upgrade-step-3">
    <!-- HARDWARE KIT PREFERENCES SECTION -->
    <div
      v-if="hardwarePreferences.length"
    >
      <div
        v-for="preference in hardwarePreferences"
        :key="preference.get"
      >
        <FormGroup
          :for="preference.get"
          :label="preference.label"
          class="w-100"
        >
          <FormSelect
            :options="preference.options"
            :bind-id="preference.get"
            :get="preference.get"
            :set="preference.set"
            required
          />
        </FormGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hardware from '../../data/hardware.json';

import FormGroup from '../Form/FormGroup.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'GuestUpgradeStep3',
  components: {
    FormGroup,
    FormSelect,
  },
  computed: {
    ...mapGetters([
      'hardwareSelected',
    ]),
    hardwarePreferences() {
      if (!this.hardwareSelected || this.hardwareSelected === 'none') return [];
      const pref = hardware[this.hardwareSelected].preferences;
      if (!pref) return [];
      return hardware.preferences[pref];
    },
  },
};
</script>
